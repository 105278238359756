<template>
  <div v-if="hasPerm('youth.add_seanceinscription')">
    <b-modal dialog-class="modal-xl"
      id="bv-modal-multi-inscriptions"
      ok-title="Fermer"
      ok-variant="secondary"
      ok-only
      @hide="onClose"
    >
      <template v-slot:modal-title>
        <b><i :class="youthHomeIcon"></i>
          Inscriptions - {{ youthHome.name }} - {{ seanceDate | dateToString('dddd LL') }}
        </b>
      </template>
      <b-row v-if="manager" class="multi-inscription-modal">
        <b-col cols="3" class="col-param">
          <div class="section">
            <input
              type="text"
              v-model="nameFilter"
              id="name-filter"
              class="form-control"
              :disabled="isLoading(loadingName)"
              placeholder="Entrez un nom ou prénom"
            />
            <div class="text-right small-text">
              <b-form-checkbox id="view-all" v-model="viewAll" :disabled="(!!nameFilter) || isLoading(loadingName)">
                Tous
              </b-form-checkbox>
            </div>
          </div>
          <div v-if="filteredIndividuals.length === 0" class="empty-text">
            <span v-if="nameFilter">Aucun membre ne correspond à ce nom</span>
            <span v-else>Saisissez le début du nom ou du prénom du membre à inscrire</span>
          </div>
          <div class="individuals-list">
            <div
              class="individual-line"
              :class="{'selected' : selectedIndividual === individual }"
              v-for="individual of filteredIndividuals"
              :key="individual.id"
            >
              <span v-if="individual.entities.length >= 1">
                <span v-if="selectedIndividual !== individual">
                  <a href @click.prevent="selectIndividual(individual, individual.entities[0])">
                   {{ individual.firstAndLastName() }}
                  </a>
                </span>
                <span v-else>
                  {{ individual.firstAndLastName() }}
                </span>
              </span>
              <div v-if="individual.entities.length > 1 && selectedIndividual === individual">
                <a
                  v-for="entity of individual.entities" :key="entity.id"
                  href @click.prevent="selectIndividual(individual, entity)"
                  class="family-select"
                  :class="(selectedEntity && (selectedEntity.id === entity.id)) ? 'selected-entity' : ''"
                >
                 Famille {{ entity.name }}
                </a>
              </div>
              <span v-if="isAlreadyIn(individual)" class="badge badge-dark">déjà inscrit</span>
            </div>
          </div>
        </b-col>
        <b-col>
          <loading-gif :loading-name="loadingName"></loading-gif>
          <div v-if="!isLoading(loadingName)">
            <b-row v-if="hasOpeningHours">
              <b-col>
                <hour-element
                  :opening-hours="openingHours"
                ></hour-element>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="text-right">
                <set-time-selector
                  :opening-hours="openingHours"
                  v-model="clockStatus"
                ></set-time-selector>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div v-if="selectedIndividual">
                  <div class="individual-line selected">
                    {{ selectedIndividual.firstAndLastName() }}
                    <span v-if="selectedIndividual.entities.length > 1">
                      - Famille {{ selectedEntity.name }}
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col v-if="errorText || infoText" cols="3">
                <div class="error-text" v-if="errorText">
                  <i class="fa fa-error"></i> {{ errorText }}
                </div>
                <div class="info-text" v-if="infoText">
                  <i class="fa fa-check"></i> {{ infoText }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="line-header" v-if="!isLoading(loadingName) && selectedIndividual && selectedEntity">
                  <add-individual-discount
                    :youthHome="youthHome"
                    :individual="selectedIndividual"
                    :entity="selectedEntity"
                    @discounts-changed="onDiscountsChanged"
                  ></add-individual-discount>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div v-if="selectedIndividual">
                  <loading-gif :loading-name="seancesLoadingName"></loading-gif>
                  <b-row>
                    <b-col>
                      <div
                        v-for="seance in manager.getSeances()"
                        :key="seance.id"
                        :style="'background: ' + manager.getSeanceColor(seance)"
                        :title="seance.name"
                        class="grid-col"
                      >
                        <div class="checkbox-holder">
                          <input
                            type="checkbox"
                            :checked="seance.doesIndividualHaveInscription(selectedIndividual.id)"
                            @click="manager.setIndividualInscription(seance, selectedIndividual)"
                            :disabled="!manager.isInscriptionPossible(seance, selectedIndividual)"
                            class="inline"
                          /><span></span>
                          <span class="checkbox-label">
                            {{ seance.getShortName() }}
                          </span>
                          <workshop-select
                            :seance="seance"
                            :individual="selectedIndividual"
                            v-if="seance.doesIndividualHaveInscription(selectedIndividual.id)"
                          >
                          </workshop-select>
                        </div>
                      </div>
                    </b-col>
                    <b-col v-show="newInscriptions.length || cancellations.length">
                      <div v-if="cancellations.length > 0" class="field-line">
                        <div class="header-line">
                          <b>Annulations</b>
                        </div>
                        <div
                          v-for="inscription in cancellations"
                           :key="inscription.getKey()"
                           class="field-line"
                        >
                          <b-row class="ut-cancellation">
                            <b-col>
                              {{ inscription.seance.getShortName() }}
                              <div>
                                <workshop-select
                                  initial-value
                                  :seance="inscription.seance"
                                  :individual="inscription.individual"
                                ></workshop-select>
                              </div>
                            </b-col>
                            <b-col class="text-right">
                              <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                              <div v-if="!isLoading(calculatePricesLoadingName)">
                                <span v-if="manager.getFinalPrice(inscription)">
                                  <span v-if="manager.isInvoiced(inscription)">
                                    <b-form-checkbox
                                      :id="'refundCancellations' + inscription.getKey()"
                                      :checked="manager.isCancellationRefund(inscription)"
                                      :name="'refundCancellations' + inscription.getKey()"
                                      :value="true"
                                      :unchecked-value="false"
                                      @change="manager.changeCancellationRefund(inscription, $event)"
                                    >
                                      avoir: {{ manager.getFinalPrice(inscription) | currency }}
                                    </b-form-checkbox>
                                  </span>
                                  <span v-else>
                                    <b-form-checkbox
                                      :id="'refundCancellations' + inscription.getKey()"
                                      :checked="manager.isCancellationCharged(inscription)"
                                      :name="'refundCancellations' + inscription.getKey()"
                                      :value="true"
                                      :unchecked-value="false"
                                      class="ut-invoice"
                                      @change="manager.changeCancellationCharged(inscription, $event)"
                                    >
                                      facturable: {{ manager.getFinalPrice(inscription) | currency }}
                                    </b-form-checkbox>
                                  </span>
                                </span>
                                <span v-else>{{ 0 | currency }}</span>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <div v-if="newInscriptions.length > 0" class="field-line">
                        <div class="header-line ut-inscriptions">
                          <b>Nouvelles inscriptions</b>
                        </div>
                        <div  class="limit-indicator-info">Les limites n'incluent pas les inscriptions ci-dessous</div>
                        <div
                          v-for="inscription in newInscriptions"
                           :key="inscription.getKey()"
                           class="field-line"
                        >
                          <b-row class="ut-inscription">
                            <b-col>
                              <div>
                                {{ inscription.seance.getShortName() }}
                                <div>
                                  <seance-limits-loader
                                    :individual="inscription.individual"
                                    :seance="inscription.seance"
                                  >
                                  </seance-limits-loader>
                                  <workshop-select
                                    :seance="inscription.seance"
                                    :individual="inscription.individual"
                                  ></workshop-select>
                                </div>
                              </div>
                            </b-col>
                            <b-col class="text-right">
                              <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                              <div v-if="!isLoading(calculatePricesLoadingName)">
                                {{ manager.getFinalPrice(inscription) | currency }}
                                <div
                                  class="badge badge-light"
                                  v-for="welfare of manager.getWelfare(inscription)"
                                  :key="welfare.id"
                                >
                                  {{ welfare.name }}: {{ welfare.getWelfareAmount() }}
                                </div>
                                <span
                                  v-for="discount of manager.getDiscounts(inscription)"
                                  :key="discount.id"
                                >
                                  <discount-badge :discount="discount"></discount-badge>
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                      <b-row class="buttons-bar">
                        <b-col class="text-right">
                          <loading-gif :loading-name="seancesCreationLoading" :short="true"></loading-gif>
                          <a
                            class="btn btn-secondary"
                            href
                            @click.prevent="onCancel()"
                          >
                            Annuler
                          </a>
                          <a
                            class="btn btn-primary btn-save"
                            :class="{ disabled: isLoading(seancesCreationLoading) }"
                            href
                            @click.prevent="onConfirmInscriptions()"
                          >
                            Inscrire
                          </a>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col v-if="!(newInscriptions.length || cancellations.length)">
                <div class="section">
                  <div v-for="inscription in individualInscriptions" :key="inscription.id" class="inscription-line">
                    <b-row>
                      <b-col cols="5">
                        {{ inscription.seance.getShortName() }}
                      </b-col>
                      <b-col cols="7" class="text-right">
                        <span class="badge badge-danger" v-if="inscription.absence">Absence</span>
                        <time-selector
                          v-if="hasOpeningHours"
                          :inscription="inscription"
                          :opening-hours="openingHours"
                          :clock-status="clockStatus"
                        >
                        </time-selector>
                      </b-col>
                    </b-row>
                  </div>
                  <div v-if="selectedIndividual">
                    <inscription-invoicing
                      :seance-date="seanceDate"
                      :entity="selectedEntity"
                      :youth-home="youthHome"
                    >
                    </inscription-invoicing>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import AddIndividualDiscount from '@/components/Discounts/AddIndividualDiscount'
import HourElement from '@/components/Seances/HourElement'
import SetTimeSelector from '@/components/Seances/SetTimeSelector'
import SeanceLimitsLoader from '@/components/SeanceLimits/SeanceLimitsLoader'
import TimeSelector from '@/components/Seances/TimeSelector'
import WorkshopSelect from '@/components/Seances/WorkshopSelect'
import InscriptionInvoicing from '@/components/Youth/InscriptionInvoicing'
import DiscountBadge from '@/components/Discounts/DiscountBadge'
import { BackendMixin } from '@/mixins/backend'
import { DayMoments, getDayTimes } from '@/types/youth'
import { existsIn } from '@/utils/arrays'
import { includes } from '@/utils/strings'
import { SeanceInscriptionManager } from '@/utils/youth'
import store from '@/store'

export default {
  name: 'multi-inscriptions-modal',
  components: {
    DiscountBadge,
    InscriptionInvoicing,
    SetTimeSelector,
    AddIndividualDiscount,
    HourElement,
    LoadingGif,
    WorkshopSelect,
    SeanceLimitsLoader,
    TimeSelector,
  },
  mixins: [BackendMixin],
  props: {
    day: String,
    elements: Array,
    allMembers: Array,
    membersLoaded: Boolean,
    seanceType: Object,
    youthHome: Object,
    period: Object,
    openingHours: Object,
    hasOpeningHours: Boolean,
    rules: Array,
  },
  data() {
    return {
      manager: null,
      errorText: '',
      infoText: '',
      loadingName: 'multi-inscriptions',
      seancesLoadingName: 'multi-inscriptions-seances',
      seancesCreationLoading: 'multi-inscriptions-create',
      selectedIndividual: null,
      selectedEntity: null,
      nameFilter: '',
      selectedMoment: DayMoments.None,
      clockStatus: 0,
      dayMoments: DayMoments,
      dayTimes: [],
      allDiscounts: {},
      calculatePricesLoadingName: 'multi-inscriptions-price',
      allInscriptions: [],
      viewAll: false,
    }
  },
  computed: {
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    seanceDate() {
      return moment(this.day).toDate()
    },
    filteredIndividuals() {
      if (this.viewAll && !this.nameFilter) {
        return this.allMembers
      }
      return this.allMembers.filter(
        elt => {
          if (!this.nameFilter) {
            return false
          } else {
            return this.isIndividualSelected(elt) || includes(elt.firstAndLastName(), this.nameFilter)
          }
        }
      )
    },
    isToday() {
      return moment(this.day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    },
    newInscriptions() {
      let newInscriptions = []
      if (this.selectedIndividual && this.manager) {
        newInscriptions = this.manager.newInscriptions()
      }
      return newInscriptions
    },
    cancellations() {
      let cancellations = []
      if (this.selectedIndividual && this.manager) {
        cancellations = this.manager.cancellations()
      }
      return cancellations
    },
    individualInscriptions() {
      if (this.selectedIndividual) {
        for (let elt of this.allInscriptions) {
          if (elt.individual.id === this.selectedIndividual.id) {
            return elt.inscriptions
          }
        }
      }
      return []
    },
  },
  watch: {
    activityId: function() {},
    youthHome: function() {},
    openingHours: function() {},
    hasOpeningHours: function() {},
    newInscriptions: function() {
      this.onInscriptionsChanged()
    },
    cancellations: function() {
      this.onInscriptionsChanged()
    },
    elements: function(newValue) {
      this.allInscriptions = newValue
    },
    membersLoaded: function(value) {
      if (!value) {
        this.startLoading(this.loadingName)
      } else {
        this.endLoading(this.loadingName)
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    isIndividualSelected(individual) {
      return this.selectIndividual === individual
    },
    async selectIndividual(individual, entity) {
      this.selectedIndividual = individual
      this.selectedEntity = entity
      this.manager.setIndividual(individual, entity)
      this.startLoading(this.seancesLoadingName)
      this.errorText = ''
      try {
        await this.manager.loadIndividualSeances(this.seanceDate)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
      this.endLoading(this.seancesLoadingName)
    },
    isAlreadyIn(individual) {
      const index = this.allInscriptions.map(elt => elt.individual.id).indexOf(individual.id)
      if (index >= 0) {
        return this.allInscriptions[index].inscriptions.length > 0
      }
      return false
    },
    onDiscountsChanged(discounts) {
      if (this.manager) {
        this.manager.setUserDiscounts(discounts)
      }
    },
    async onInscriptionsChanged() {
      this.startLoading(this.calculatePricesLoadingName)
      try {
        await this.manager.loadPrices()
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
      this.endLoading(this.calculatePricesLoadingName)
    },
    onCancel() {
      const individual = this.selectedIndividual
      this.selectIndividual(individual, individual.entities[0])
    },
    async onConfirmInscriptions() {
      if (this.selectIndividual && !this.isLoading(this.seancesCreationLoading)) {
        this.startLoading(this.seancesCreationLoading)
        try {
          await this.manager.confirmInscriptions(this.updateAfterConfirm)
        } catch (err) {
          this.errorText = this.getErrorText(err)
          this.endLoading(this.seancesCreationLoading)
        }
      }
    },
    updateAfterConfirm(createdInscriptions, cancelledSeances) {
      // If the individual already has some inscriptions : update with newly created
      let found = false
      const createdInscriptionsIds = createdInscriptions.map(elt => elt.id)
      for (const elt of this.elements) {
        if (elt.individual.id === this.selectedIndividual.id) {
          found = true
          // remove cancelled inscriptions
          const existingInscriptions = elt.inscriptions.filter(
            ins => {
              return (
                (cancelledSeances.indexOf(ins.seance.id) < 0) &&
                (!existsIn([ins.id], createdInscriptionsIds))
              )
            }
          )
          elt.inscriptions = createdInscriptions.concat(existingInscriptions)
          break
        }
      }
      if (!found) {
        // If no individual yet : add it to the list
        this.allInscriptions = [
          {
            individual: this.selectedIndividual,
            inscriptions: createdInscriptions,
          }
        ].concat(this.allInscriptions)
      } else {
        this.allInscriptions = this.allInscriptions.concat([])
      }
      this.infoText = 'Les inscriptions ont été prises en compte'
      this.endLoading(this.seancesCreationLoading)
      setTimeout(
        () => {
          this.infoText = ''
          this.errorText = ''
        },
        3000
      )
    },
    onClose() {
      this.selectedEntity = null
      this.selectedIndividual = null
      this.$emit('close', {})
    },
  },
  created() {
    this.manager = new SeanceInscriptionManager(this.youthHome, this.seanceType, this.period)
    this.manager.setInscriptionRules(this.rules)
    this.dayTimes = getDayTimes()
    this.allInscriptions = this.elements.concat([])
  },
}
</script>
<style lang="less">
.multi-inscription-modal {
  .individuals-list {
    height: 300px;
    overflow-y: scroll;
    font-size: 12px;
  }

  .inscription-line, .individual-line {
    padding: 1px;
    font-size: 12px;
    border-bottom: solid 1px #f0f0f0;
    margin-bottom: 2px;
  }

  .individual-line a {
    color: #000 !important;
  }

  .individual-line.selected {
    background: #222;
    color: #fff !important;
  }

  .individual-line.selected a {
    color: #fff !important;
  }

  .individual-line:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }

  .error-text {
    padding: 5px;
    color: #000;
    background: #f78888;
  }

  .info-text {
    padding: 5px;
    color: #000;
    background: #7db093;
  }

  .col-param .section {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px #e0e0e0;
  }

  .checkbox-holder {
    position: relative;
    display: inline-block;
  }

  .checkbox-holder input[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    top: 4px;
    left: 2px;
    display: inline-block;
    vertical-align: top;
  }

  .checkbox-holder input[type="checkbox"] + span:before {
    font: 14pt 'Font Awesome 6 Free';
    content: '\f096';
    display: inline-block;
    width: 14pt;
    padding: 2px 0 0 3px;
    margin-right: 0.5em;
    vertical-align: top;
  }

  .checkbox-holder input[type="checkbox"]:checked + span:before {
    content: '\00f046';
  }

  .checkbox-holder input[type="checkbox"]:focus + span:before {
    outline: 1px dotted #aaa;
  }

  .checkbox-holder input[type="checkbox"]:disabled + span {
    color: #aaa;
    cursor: not-allowed;
  }

  .checkbox-label {
    display: inline-block;
    vertical-align: top;
  }

  .line-header {
    padding: 2px;
    background: #e0e0e0;
    margin: 5px 0;
  }
  .family-select {
    text-decoration: underline;
    display: inline-block;
    margin: 1px 3px;
    padding: 1px 4px;
  }
  a.selected-entity {
    background: #888;
  }
}
</style>
