<template>
  <div v-if="openingHours">
    <div
      v-for="dayMoment of dayMoments"
      :key="dayMoment"
      class="hour-elt-holder"
    >
      <div class="hour-elt" :class="getMomentClass(dayMoment)">
        <div v-if="getLabel1(dayMoment)">
          {{ getLabel1(dayMoment) }}: <span class="hour-limit">{{ getTime1(dayMoment) | hour }}</span>
        </div>
        <div v-if="getLabel2(dayMoment)">
          {{ getLabel2(dayMoment) }}: <span class="hour-limit">{{ getTime2(dayMoment) | hour }}</span>
        </div>
        <div v-if="getLabel3(dayMoment)">
          {{ getLabel3(dayMoment) }}: <span class="hour-limit">{{ getTime3(dayMoment) | hour }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { DayMoments } from '@/types/youth'

export default {
  name: 'HourElement',
  props: {
    openingHours: Object,
  },
  data() {
    return {
    }
  },
  watch: {
    selectedDayMoment: function() {},
  },
  computed: {
    dayMoments() {
      const dayMoments = []
      if (this.openingHours.openingAt) {
        dayMoments.push(DayMoments.Opening)
      }
      if (this.openingHours.closingAt) {
        dayMoments.push(DayMoments.Closing)
      }
      if (this.openingHours.openingAt2) {
        dayMoments.push(DayMoments.Opening2)
      }
      if (this.openingHours.closingAt2) {
        dayMoments.push(DayMoments.Closing2)
      }
      if (this.openingHours.openingAt3) {
        dayMoments.push(DayMoments.Opening3)
      }
      if (this.openingHours.closingAt3) {
        dayMoments.push(DayMoments.Closing3)
      }
      return dayMoments
    },
  },
  methods: {
    getLabel1(dayMoment) {
      if (this.openingHours) {
        if (dayMoment === DayMoments.Opening) {
          return this.openingHours.openingAt2 ? 'Ouvert. matin' : 'Ouverture'
        }
        if (dayMoment === DayMoments.Closing) {
          return this.openingHours.minDepartureAt ? 'Début départ' : ''
        }
        if (dayMoment === DayMoments.Opening2) {
          return this.openingHours.lunchStartsAt ? 'Début repas' : ''
        }
        if (dayMoment === DayMoments.Closing2) {
          return this.openingHours.minDepartureAt2 ? 'Début départ' : ''
        }
        if (dayMoment === DayMoments.Opening3) {
          return this.openingHours.openingAt3 ? 'Ouvert. soirée' : ''
        }
      }
      return ''
    },
    getTime1(dayMoment) {
      if (this.openingHours) {
        if (dayMoment === DayMoments.Opening) {
          return this.openingHours.openingAt
        }
        if (dayMoment === DayMoments.Closing) {
          return this.openingHours.minDepartureAt
        }
        if (dayMoment === DayMoments.Opening2) {
          return this.openingHours.lunchStartsAt
        }
        if (dayMoment === DayMoments.Closing2) {
          return this.openingHours.minDepartureAt2
        }
        if (dayMoment === DayMoments.Opening3) {
          return this.openingHours.openingAt3
        }
      }
      return null
    },
    getLabel2(dayMoment) {
      if (this.openingHours) {
        if (dayMoment === DayMoments.Opening) {
          return this.openingHours.maxArrivalAt ? 'Fin d\'accueil' : ''
        }
        if (dayMoment === DayMoments.Closing) {
          return this.openingHours.closingAt2 ? 'Fermet. matin' : 'Fermeture'
        }
        if (dayMoment === DayMoments.Opening2) {
          return this.openingHours.openingAt2 ? 'Ouvert. soir' : ''
        }
        if (dayMoment === DayMoments.Closing2) {
          return this.openingHours.closingAt2 ? 'Fermet. soir' : ''
        }
        if (dayMoment === DayMoments.Closing3) {
          return this.openingHours.closingAt3 ? 'Fermet. soirée' : ''
        }
      }
      return ''
    },
    getTime2(dayMoment) {
      if (this.openingHours) {
        if (dayMoment === DayMoments.Opening) {
          return this.openingHours.maxArrivalAt
        }
        if (dayMoment === DayMoments.Closing) {
          return this.openingHours.closingAt
        }
        if (dayMoment === DayMoments.Opening2) {
          return this.openingHours.openingAt2
        }
        if (dayMoment === DayMoments.Closing2) {
          return this.openingHours.closingAt2
        }
        if (dayMoment === DayMoments.Closing3) {
          return this.openingHours.closingAt3
        }
      }
      return null
    },
    getLabel3(dayMoment) {
      if (this.openingHours) {
        if (dayMoment === DayMoments.Closing) {
          return this.openingHours.lunchEndsAt ? 'Fin repas' : ''
        }
        if (dayMoment === DayMoments.Opening2) {
          return this.openingHours.maxArrivalAt2 ? 'Fin d\'accueil' : ''
        }
      }
      return ''
    },
    getTime3(dayMoment) {
      if (this.openingHours) {
        if (dayMoment === DayMoments.Closing) {
          return this.openingHours.lunchEndsAt
        }
        if (dayMoment === DayMoments.Opening2) {
          return this.openingHours.maxArrivalAt2
        }
      }
      return null
    },
    getMomentClass(dayMoment) {
      if (dayMoment === DayMoments.Opening) {
        return 'arrived-at'
      }
      if (dayMoment === DayMoments.Closing) {
        return 'left-at'
      }
      if (dayMoment === DayMoments.Opening2) {
        return 'arrived-at2'
      }
      if (dayMoment === DayMoments.Closing2) {
        return 'left-at2'
      }
      if (dayMoment === DayMoments.Opening3) {
        return 'arrived-at3'
      }
      if (dayMoment === DayMoments.Closing3) {
        return 'left-at3'
      }
      return ''
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
.hour-elt-holder {
  width: 25%;
  display: inline-block;
  vertical-align: top;
}
.hour-elt {
  padding: 2px;
  margin: 2px 15px 2px 0;
  text-align: center;
  font-size: 11px;
}
//.hour-elt-holder:last-of-type .hour-elt {
//  margin-right: 0;
//}
.bold {
  font-weight: bolder;
}
.clock-btn {
  margin-left: 2px;
  color: #fff;
  background: #000;
  padding: 2px 5px;
  border-radius: 4px;
  font-size: 14px;
}
.hour-limit{
  font-weight: bold;
  font-size: 15px;
}
</style>
