<template>
  <div>
    <div class="field-line">
      <div v-if="!!discounts[individualId]" class="ut-discount">
        <b-row >
          <b-col class="inline-checkboxes">
            <b-checkbox
              :checked="discountsChecked[individualId] && !extraChecked[individualId]"
              :value="true"
              :unchecked-value="false"
              :id="'discount-' + individualId"
              @change="setDiscountChecked(individualId, $event)"
            >
              <b>Réduction</b>
            </b-checkbox>
            <b-checkbox
              :checked="discountsChecked[individualId] && extraChecked[individualId]"
              :value="true"
              :unchecked-value="false"
              :id="'extra-' + individualId"
              @change="setExtraChecked(individualId, $event)"
            >
              <b>Supplément</b>
            </b-checkbox>
             pour {{ individual.firstName }}
            <div class="small2" v-if="discountsChecked[individualId] && !extraChecked[individualId]">
              La réduction sera appliquée sur chaque séance payante cochée
            </div>
            <div class="small2" v-if="discountsChecked[individualId] && extraChecked[individualId]">
              Le supplément sera appliquée sur chaque séance payante cochée
            </div>
          </b-col>
        </b-row>
        <b-row v-if="discountsChecked[individualId]">
          <b-col cols="3">
            <b-checkbox
              v-model="discounts[individualId].showPercentage"
              :value="true"
              class="ut-show-percentage"
              :unchecked-value="false"
              @change="setDiscountShowPercentage(individualId, $event)"
            >
              En pourcentage
            </b-checkbox>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label-cols="2"
              :id="'addDiscountAmountGroup' + individualId"
              label="€"
              :label-for="'addDiscountAmount' + individualId"
              v-if="!discounts[individualId].showPercentage"
            >
              <decimal-input
                :id="'addDiscountAmount' + individualId"
                v-model="discounts[individualId].amount"
                :disabled="!discountsChecked[individualId]"
                @change="setDiscountAmount(individualId, $event)"
              >
              </decimal-input>
            </b-form-group>
            <b-form-group
              :id="'addDiscountPercentageGroup' + individualId"
              label-cols="2"
              label="%"
              :label-for="'addDiscountPercentage' + individualId"
              v-if="discounts[individualId].showPercentage"
            >
              <b-form-input
                :id="'addDiscountPercentage' + individualId"
                v-model="discounts[individualId].percentage"
                type="number"
                :disabled="!discountsChecked[individualId]"
                min="0"
                step="1"
                max="100"
                @change="setDiscountPercentage(individualId, $event)"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :id="'addDiscountCommentsGroup' + individualId"
              :label-for="'addDiscountComments' + individualId"
            >
              <b-form-input
                placeholder="Commentaires"
                :id="'addDiscountComments' + individualId"
                v-model="discounts[individualId].comments"
                :disabled="!discountsChecked[individualId]"
                class="ut-comments"
                @change="setDiscountComments(individualId, $event)"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput'
import { BackendMixin } from '@/mixins/backend'
import { makeChild } from '@/types/families'
import { makeDiscount } from '@/types/payments'
import { BackendApi } from '@/utils/http'

export default {
  name: 'AddIndividualDiscount',
  components: { DecimalInput, },
  props: {
    individual: Object,
    entity: Object,
    youthHome: Object,
  },
  mixins: [BackendMixin],
  data() {
    return {
      shown: false,
      discountsChecked: {},
      extraChecked: {},
      discounts: {},
    }
  },
  computed: {
    individualId() {
      return this.individual ? this.individual.id : 0
    },
  },
  watch: {
    entity: function() {
      this.loadChildrenDiscounts()
    },
    discounts: {
      handler: function() {
        this.discountsChanged()
      },
      deep: true,
    },
    discountsChecked: {
      handler: function() {
        this.discountsChanged()
      },
      deep: true,
    },
    extraChecked: {
      handler: function() {
        this.discountsChanged()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['addError']),
    setDiscountChecked(individualId, value) {
      this.discountsChecked[individualId] = value
      this.extraChecked[individualId] = false
      this.discountsChecked = { ...this.discountsChecked, }
      this.extraChecked = { ...this.extraChecked, }
    },
    setExtraChecked(individualId, value) {
      this.discountsChecked[individualId] = value
      this.extraChecked[individualId] = value
      this.discountsChecked = { ...this.discountsChecked, }
      this.extraChecked = { ...this.extraChecked, }
    },
    setDiscountShowPercentage(individualId, value) {
      this.discounts[individualId].showPercentage = value
      this.discounts = { ...this.discounts, }
    },
    setDiscountPercentage(individualId, value) {
      this.discounts[individualId].percentage = value
      this.discounts = { ...this.discounts, }
    },
    setDiscountAmount(individualId, value) {
      this.discounts[individualId].amount = value
      this.discounts = { ...this.discounts, }
    },
    setDiscountComments(individualId, value) {
      this.discounts[individualId].comments = value
      this.discounts = { ...this.discounts, }
    },
    isZero(individualId) {
      let percentage = +this.discounts[individualId].percentage
      let amount = +this.discounts[individualId].amount
      return (this.discounts[individualId].showPercentage) ? (percentage === 0) : (amount === 0)
    },
    discountsChanged() {
      let discounts = {}
      let individualId = this.individualId
      if (this.discountsChecked[individualId] && !this.isZero(individualId)) {
        const discount = { ...this.discounts[individualId], }
        if (this.extraChecked[individualId]) {
          discount.percentage = -discount.percentage
          discount.amount = -discount.amount
        }
        discounts[individualId] = discount
      }
      this.$emit('discounts-changed', discounts)
    },
    async loadChildrenDiscounts() {
      let backendApi = null
      let url = '/api/family-children/' + this.entity.id + '/'
      backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.discounts = {}
        this.discounts[this.individualId] = makeDiscount()
        let children = resp.data.map(elt => makeChild(elt))
        this.childrenData = children.reduce(
          (childrenData, child) => {
            if (child.individualId === this.individual.id) {
              let childDiscount = child.discountPercentage || 0
              this.discountsChecked[child.individualId] = !!childDiscount
              let discount = makeDiscount()
              if (childDiscount) {
                discount.showPercentage = true
                discount.percentage = childDiscount
              }
              this.discounts[child.individualId] = discount
              childrenData[child.individualId] = child
            }
            return childrenData
          },
          {}
        )
        this.discounts = { ...this.discounts, }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
  mounted() {
    this.loadChildrenDiscounts()
  },
}
</script>
<style scoped lang="less">
.inline-checkboxes .custom-control {
  display: inline-block;
  margin-right: 5px;
}
</style>
