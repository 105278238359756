<template>
  <span>
    <div v-for="parent in element.parents" :key="parent.id" class="small2">
      {{ parent.firstAndLastName() }} : {{ getPhoneNumber(element, parent) }}
    </div>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'InscriptionParents',
  mixins: [BackendMixin],
  props: {
    element: Object,
  },
  data() {
    return {
    }
  },
  watch: {
    elements: function() {},
  },
  computed: {
  },
  methods: {
    getPhoneNumber(elt, parent) {
      if (parent.cellPhone) {
        return parent.cellPhone
      } else if (parent.proPhone) {
        return parent.proPhone
      } else if (elt.entity && elt.entity.phone) {
        return elt.phone
      }
      return '-'
    },
  },
}
</script>
<style lang="less">
</style>
