<template>
  <div v-if="hasPerm('payments.view_invoice') && youthHome.allowInvoicing">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="sales.length" class="inscription-invoicing-list">
      <b>
        <counter-label label="vente à facturer" label-n="ventes à facturer" :counter="sales.length">
        </counter-label>
      </b>
      <div v-for="sale of sales" :key="sale.id" class="inscription-invoicing-item">
        <b-row>
          <b-col cols="9" class="inscription-invoicing-label">
            {{ sale.label }}
          </b-col>
          <b-col class="text-right">
            {{ sale.price | currency }}
          </b-col>
        </b-row>
      </div>
      <div class="text-right">
        <a href @click.prevent="createInvoice()" class="btn btn-primary">Facturer</a>
      </div>
    </div>
    <div v-if="invoices.length" class="inscription-invoicing-list">
      <b><counter-label label="facture" :counter="invoices.length"></counter-label></b>
      <div v-for="invoice of invoices" :key="invoice.id" class="inscription-invoicing-item">
        <b-row>
          <b-col>
            <invoice-badge :invoice="invoice"></invoice-badge>
          </b-col>
          <b-col class="text-right">
            <payment-button
              css-class="btn btn-secondary btn-sm"
              :invoice="invoice"
              v-if="!invoice.isPaid"
              :entity="invoice.entity"
              @paid="refresh()"
            ></payment-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel'
import LoadingGif from '@/components/Controls/LoadingGif'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import PaymentButton from '@/components/Payments/PaymentButton'
import { BackendMixin } from '@/mixins/backend'
import { makeInvoice, makeSale } from '@/types/payments'
import { BackendApi } from '@/utils/http'

export default {
  name: 'inscription-invoicing',
  components: {
    PaymentButton,
    CounterLabel,
    InvoiceBadge,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    seanceDate: Date,
    entity: Object,
    youthHome: Object,
    reload: Boolean,
    includeNotPaid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sales: [],
      invoices: [],
      loadingName: 'inscriptionInvoicesName',
    }
  },
  computed: {
  },
  watch: {
    entity: function() {
      this.loadData()
    },
    reload: function() {
      if (this.reload) {
        this.loadData()
        this.$emit('reloaded')
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.invoices = []
      this.sales = []
      if (this.hasPerm('payments.view_invoice')) {
        this.startLoading(this.loadingName)
        try {
          const theDate = moment(this.seanceDate).format('YYYY-MM-DD')
          let url = '/api/youth/inscription-invoices/?seance_date=' + theDate +
            '&entity=' + this.entity.id + '&youth_home=' + this.youthHome.id
          if (this.includeNotPaid) {
            url += '&include_not_paid=1'
          }
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.invoices = resp.data.invoices.map(elt => makeInvoice(elt))
          this.sales = resp.data.sales.map(elt => makeSale(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    refresh() {
      this.loadData()
    },
    async createInvoice() {
      const data = { sales: this.sales.map(elt => elt.id), }
      let backendApi = new BackendApi(
        'post', '/api/entity/' + this.entity.id + '/create-invoice/'
      )
      this.startLoading(this.loadingName)
      try {
        let resp = await backendApi.callApi(data)
        this.sales = []
        const invoice = makeInvoice(resp.data.invoice)
        invoice.entity = this.entity
        this.invoices.push(invoice)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
  created() {
    this.loadData()
  },
}
</script>
<style lang="less">
.inscription-invoicing-list {
  margin-top: 30px;
  border: solid 1px #ccc;
  padding: 10px;
  display: block;
}
.inscription-invoicing-item {
  padding: 2px;
  margin-bottom: 2px;
  border-bottom: solid 1px #ccc;
}
.inscription-invoicing-item:last-of-type {
  padding: 2px;
  margin-bottom: 2px;
  border-bottom: solid 1px transparent;
}
.inscription-invoicing-label {
  font-size: 13px;
}
</style>
