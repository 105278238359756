<template>
  <span class="daily-inscription-modal" v-if="hasPerm('youth.add_seanceinscription')">
    <b-modal dialog-class="modal-xl"
      id="bv-modal-daily-inscriptions"
      ok-title="Fermer"
      @ok="onClose()"
      ok-variant="secondary"
      ok-only
    >
      <template v-slot:modal-title>
        <b><i :class="youthHomeIcon"></i>
          Inscription - {{ youthHome.name }} - {{ seanceDate | dateToString('dddd LL') }} -
        </b>
      </template>
      <b-row v-if="manager">
        <b-col>
          <loading-gif :loading-name="loadingName"></loading-gif>
          <div v-if="individual && entity">
            <div class="individual-line selected">
              {{ individual.firstAndLastName() }} - Famille {{ entity.name }}
            </div>
          </div>
        </b-col>
        <b-col v-if="errorText" cols="3">
          <div class="error-text" v-if="errorText">
            <i class="fa fa-error"></i> {{ errorText }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="field-line" v-if="!isLoading(loadingName) && individual && entity">
            <add-individual-discount
              :youthHome="youthHome"
              :individual="individual"
              :entity="entity"
              @discounts-changed="onDiscountsChanged"
            ></add-individual-discount>
          </div>
          <div v-if="individual">
            <loading-gif :loading-name="seancesLoadingName"></loading-gif>
            <b-row>
              <b-col>
                <div
                  v-for="seance in manager.getSeances()"
                  :key="seance.id"
                  :style="'background: ' + manager.getSeanceColor(seance)"
                  :title="seance.name"
                  class="grid-col"
                >
                  <div class="checkbox-holder">
                    <input
                      type="checkbox"
                      :checked="seance.doesIndividualHaveInscription(individual.id)"
                      @click="manager.setIndividualInscription(seance, individual)"
                      :disabled="!manager.isInscriptionPossible(seance, individual)"
                      class="inline"
                    /><span></span>
                    {{ seance.getShortName() }}
                    <workshop-select
                      :seance="seance"
                      :individual="individual"
                      v-if="seance.doesIndividualHaveInscription(individual.id)"
                    >
                    </workshop-select>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div v-show="newInscriptions.length || cancellations.length">
                  <div v-if="cancellations.length > 0" class="field-line">
                    <div class="header-line ut-cancellations">
                      <b>Annulations</b>
                    </div>
                    <div
                      v-for="inscription in cancellations"
                       :key="inscription.getKey()"
                       class="field-line"
                    >
                      <b-row>
                        <b-col cols="8">
                          {{ inscription.seance.getShortName() }}
                          <div>
                            <workshop-select
                              initial-value
                              :seance="inscription.seance"
                              :individual="inscription.individual"
                            ></workshop-select>
                          </div>
                        </b-col>
                        <b-col cols="4" class="text-right">
                          <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                          <div v-if="!isLoading(calculatePricesLoadingName)">
                            <span v-if="manager.getFinalPrice(inscription)">
                              <span v-if="manager.isInvoiced(inscription)">
                                <b-form-checkbox
                                  :id="'refundCancellations' + inscription.getKey()"
                                  :checked="manager.isCancellationRefund(inscription)"
                                  :name="'refundCancellations' + inscription.getKey()"
                                  :value="true"
                                  :unchecked-value="false"
                                  class="ut-refund"
                                  @change="manager.changeCancellationRefund(inscription, $event)"
                                >
                                  avoir: {{ manager.getFinalPrice(inscription) | currency }}
                                </b-form-checkbox>
                              </span>
                              <span v-else>
                                <b-form-checkbox
                                  :id="'refundCancellations' + inscription.getKey()"
                                  :checked="manager.isCancellationCharged(inscription)"
                                  :name="'refundCancellations' + inscription.getKey()"
                                  :value="true"
                                  :unchecked-value="false"
                                  class="ut-invoice"
                                  @change="manager.changeCancellationCharged(inscription, $event)"
                                >
                                  facturable: {{ manager.getFinalPrice(inscription) | currency }}
                                </b-form-checkbox>
                              </span>
                            </span>
                            <span v-else>{{ 0 | currency }}</span>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div v-if="newInscriptions.length > 0" class="field-line">
                    <div class="header-line ut-inscriptions">
                      <b>Nouvelles inscriptions</b>
                    </div>
                    <div  class="limit-indicator-info">Les limites n'incluent pas les inscriptions ci-dessous</div>
                    <div
                      v-for="inscription in newInscriptions"
                       :key="inscription.getKey()"
                       class="field-line"
                    >
                      <b-row>
                        <b-col cols="8">
                          <div>
                            {{ inscription.seance.getShortName() }}
                            <div>
                              <seance-limits-loader
                                :individual="inscription.individual"
                                :seance="inscription.seance"
                              >
                              </seance-limits-loader>
                              <workshop-select
                                :seance="inscription.seance"
                                :individual="inscription.individual"
                              ></workshop-select>
                            </div>
                          </div>
                        </b-col>
                        <b-col cols="4" class="text-right">
                          <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                          <div v-if="!isLoading(calculatePricesLoadingName)">
                            {{ manager.getFinalPrice(inscription) | currency }}
                            <div
                              class="badge badge-light"
                              v-for="welfare of manager.getWelfare(inscription)"
                              :key="welfare.id"
                            >
                              {{ welfare.name }}: {{ welfare.getWelfareAmount() }}
                            </div>
                            <span
                              v-for="discount of manager.getDiscounts(inscription)"
                              :key="discount.id"
                            >
                              <discount-badge :discount="discount"></discount-badge>
                            </span>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div class="text-right">
                    <a
                      class="btn btn-primary"
                      href
                      :class="{ disabled: !isSaveActive(), }"
                      @click.prevent="onSave()"
                    >
                      Inscrire
                    </a>
                  </div>
                </div>
                <inscription-invoicing
                  v-if="entity"
                  :seance-date="seanceDate"
                  :entity="entity"
                  :youth-home="youthHome"
                  :reload="reloadInvoicing"
                  @reloaded="reloadInvoicing = false"
                >
                </inscription-invoicing>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import AddIndividualDiscount from '@/components/Discounts/AddIndividualDiscount'
import SeanceLimitsLoader from '@/components/SeanceLimits/SeanceLimitsLoader.vue'
import InscriptionInvoicing from '@/components/Youth/InscriptionInvoicing'
import DiscountBadge from '@/components/Discounts/DiscountBadge'
import WorkshopSelect from '@/components/Seances/WorkshopSelect'
import { BackendMixin } from '@/mixins/backend'
import { SeanceInscriptionManager } from '@/utils/youth'
import store from '@/store'

export default {
  name: 'daily-inscriptions-modal',
  components: {
    AddIndividualDiscount,
    LoadingGif,
    WorkshopSelect,
    SeanceLimitsLoader,
    DiscountBadge,
    InscriptionInvoicing,
  },
  mixins: [BackendMixin],
  props: {
    day: String,
    element: Object,
    seanceType: Object,
    youthHome: Object,
    period: Object,
    rules: Array,
  },
  data() {
    return {
      errorText: '',
      loadingName: 'daily-inscriptions',
      seancesLoadingName: 'daily-inscriptions-seances',
      seancesCreationLoading: 'daily-inscriptions-create',
      calculatePricesLoadingName: 'daily-inscriptions-price',
      manager: null,
      reloadInvoicing: false,
    }
  },
  computed: {
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    individual() {
      if (this.element) {
        return this.element.individual
      }
      return null
    },
    entity() {
      if (this.element) {
        return this.element.entity
      }
      return null
    },
    newInscriptions() {
      if (this.manager) {
        return this.manager.newInscriptions()
      }
      return []
    },
    cancellations() {
      if (this.manager) {
        return this.manager.cancellations()
      }
      return []
    },
    seanceDate() {
      return moment(this.day).toDate()
    },
    isToday() {
      return moment(this.day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    },

  },
  watch: {
    youthHome: function() {},
    newInscriptions: function() {
      this.onInscriptionsChanged()
    },
    cancellations: function() {
      this.onInscriptionsChanged()
    },
    element: function() {
      if (this.entity && this.individual && this.manager) {
        this.manager.setIndividual(this.individual, this.entity)
        this.startLoading(this.seancesLoadingName)
        try {
          this.manager.loadIndividualSeances(this.seanceDate)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
        this.endLoading(this.seancesLoadingName)
      }
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    init() {
      this.manager = new SeanceInscriptionManager(this.youthHome, this.seanceType, this.period)
      this.manager.setInscriptionRules(this.rules)
    },
    onDiscountsChanged(discounts) {
      if (this.manager) {
        this.manager.setUserDiscounts(discounts)
      }
    },
    async onInscriptionsChanged() {
      if (this.manager) {
        this.startLoading(this.calculatePricesLoadingName)
        try {
          await this.manager.loadPrices()
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
        this.endLoading(this.calculatePricesLoadingName)
      }
    },
    async onConfirmInscriptions() {
      if (this.individual && this.manager && !this.isLoading(this.seancesCreationLoading)) {
        this.startLoading(this.seancesCreationLoading)
        try {
          await this.manager.confirmInscriptions()
          this.reloadInvoicing = true
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
        this.endLoading(this.seancesCreationLoading)
      }
    },
    onClose() {
      this.$emit('close', {})
    },
    isSaveActive() {
      return (this.individual && !this.isLoading(this.seancesCreationLoading))
    },
    onSave() {
      this.onConfirmInscriptions()
      this.$emit('save', {})
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less">
  .inscription-line, .individual-line {
    padding: 4px;
    border-bottom: solid 1px #f0f0f0;
    margin-bottom: 2px;
  }
  .individual-line a{
    color: #000 !important;
  }
  .individual-line.selected {
    background: #222;
    color: #fff !important;
  }
  .individual-line.selected a{
    color: #fff !important;
  }
  .individual-line:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
  .error-text {
    padding: 5px;
    color: #000;
    background: #f78888;
  }
  .col-param .section {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px #e0e0e0;
  }
  .checkbox-holder {
    position: relative;
    display: inline-block;
  }
  .checkbox-holder input[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    top: 4px;
    left: 2px;
  }
  .checkbox-holder input[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    top: 4px;
    left: 2px;
  }
  .checkbox-holder input[type="checkbox"] + span:before {
    font: 14pt 'Font Awesome 6 Free';
    content: '\f096';
    display: inline-block;
    width: 14pt;
    padding: 2px 0 0 3px;
    margin-right: 0.5em;
  }
  .checkbox-holder input[type="checkbox"]:checked + span:before {
    content: '\00f046';
  }
  .checkbox-holder input[type="checkbox"]:focus + span:before {
    outline: 1px dotted #aaa;
  }
  .checkbox-holder input[type="checkbox"]:disabled + span {
    color: #aaa;
    cursor: not-allowed;
  }
</style>
