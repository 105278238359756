<template>
  <span class="daily-inscription-and-pay-modal" v-if="hasPerm('youth.add_seanceinscription')">
    <b-modal dialog-class="modal-xl modal-full"
      id="bv-modal-daily-inscriptions-and-pay"
      ok-title="Fermer"
      @ok="onClose()"
      ok-variant="secondary"
      ok-only
    >
      <template v-slot:modal-title>
        <b><i :class="youthHomeIcon"></i>
          Inscription - {{ youthHome.name }} - {{ seanceDate | dateToString('dddd LL') }} -
        </b>
      </template>
      <b-row v-if="entity">
        <b-col cols="12">
          <div class="line-header">
            <div v-for="individual of individuals" :key="individual.id">
              <add-individual-discount
                :youthHome="youthHome"
                :individual="individual"
                :entity="entity"
                @discounts-changed="onDiscountsChanged"
              ></add-individual-discount>
            </div>
          </div>
          <entity-seances-list
            :entity="entity"
            :elt="synthesisElt"
            :seances-filter="null"
            :only-individuals="individuals"
            :only-day="this.day"
            :autoload="true"
            @seances-loaded="onSeancesLoaded"
            @inscription-changed="onInscriptionChanged"
          >
          </entity-seances-list>
          <div>
            <b-row>
              <b-col>
                <div
                  v-for="inscription in newInscriptions"
                   :key="inscription.getKey()"
                   class="field-line field-line-small"
                >
                  <b-row>
                    <b-col>{{ inscription.individual.firstAndLastName() }}</b-col>
                    <b-col>
                      {{ inscription.seance.getShortName() }}
                      <span class="multiplier" v-if="getMultiplierDelta(inscription) > 0">
                        <span v-if="getInitialMultiplier(inscription) > 0">
                            X <counter-label :counter="getMultiplierDelta(inscription)" label="supplémentaire">
                            </counter-label>
                        </span>
                        <span v-else>
                            X <counter-label :counter="getMultiplierDelta(inscription)" label="inscription">
                            </counter-label>
                        </span>
                      </span>
                      <span class="multiplier" v-else>
                          X <counter-label :counter="-1 * getMultiplierDelta(inscription)" label="annulation">
                          </counter-label>
                      </span>
                    </b-col>
                    <b-col cols="2" class="text-right">
                      <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                      <div v-if="!isLoading(calculatePricesLoadingName)">
                        <span v-if="priceError">
                          <i class="fa fa-exclamation-circle" v-b-tooltip="priceError"></i>
                        </span>
                        <span v-else>
                          <div v-if="getPriceMessage(inscription)" class="badge badge-light">
                            {{ getPriceMessage(inscription) }}
                          </div>
                          {{ getFinalPrice(inscription) | currency }}
                          <div>
                            <div
                              class="badge badge-light badge-block"
                              v-for="welfare of getWelfare(inscription)"
                              :key="welfare.id"
                            >
                              {{ welfare.name }}: {{ welfare.getWelfareAmount() }}
                            </div>
                            <div
                              class="badge badge-light ut-discount"
                              v-for="discount of getDiscount(inscription)"
                              :key="discount.id"
                              :title="getPriceTitle(inscription)"
                            >
                              <span v-if="discount.showPercentage">
                                <span v-if="discount.percentage > 0">
                                  Réduction {{ discount.percentage }}%
                                </span>
                                <span v-else>
                                  Supplément {{ -discount.percentage }}%
                                </span>
                              </span>
                              <span v-else>
                                <span v-if="discount.amount > 0">
                                  Réduction {{ discount.amount | currency }}
                                </span>
                                <span v-else>
                                  Supplément {{ -discount.amount | currency }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </span>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="cancellations.length" class="field-line">
                  <b>Annulations</b>
                </div>
                <div
                  v-for="inscription in cancellations"
                   :key="inscription.getKey()"
                   class="field-line field-line-small"
                >
                  <b-row>
                    <b-col>{{ inscription.individual.firstAndLastName() }}</b-col>
                    <b-col>
                      {{ inscription.seance.getShortName() }}
                      <span class="multiplier" v-if="getMultiplierDelta(inscription) > 1">
                        x{{ getMultiplierDelta(inscription) }}
                      </span>
                    </b-col>
                    <b-col cols="3" class="text-right">
                      <loading-gif :loading-name="calculatePricesLoadingName" :short="true"></loading-gif>
                      <div v-if="!isLoading(calculatePricesLoadingName)">
                        <span v-if="getCancelPrice(inscription)">
                          <span v-if="isInvoiced(inscription)">
                            <b-form-checkbox
                              :id="'refundCancellations' + inscription.getKey()"
                              :checked="isCancellationRefund(inscription)"
                              :name="'refundCancellations' + inscription.getKey()"
                              :value="true"
                              :unchecked-value="false"
                              class="ut-refund"
                              @change="changeCancellationRefund(inscription, $event)"
                            >
                              remboursement: {{ getCancelPrice(inscription) | currency }}
                            </b-form-checkbox>
                          </span>
                          <span v-else>
                            <b-form-checkbox
                              :id="'refundCancellations' + inscription.getKey()"
                              :checked="isCancellationRefund(inscription)"
                              :name="'refundCancellations' + inscription.getKey()"
                              :value="true"
                              :unchecked-value="false"
                              class="ut-invoice"
                              @change="changeCancellationCharged(inscription, $event)"
                            >
                              facturable: {{ getCancelPrice(inscription) | currency }}
                            </b-form-checkbox>
                          </span>
                        </span>
                        <span v-else>{{ 0 | currency }}</span>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <inscription-invoicing
                  :seance-date="seanceDate"
                  :entity="entity"
                  :youth-home="youthHome"
                  :reload="reloadInvoices"
                  :include-not-paid="true"
                  @reloaded="reloadInvoices = false"
                >
                </inscription-invoicing>
              </b-col>
              <b-col cols="6">
                <div v-if="hasInscriptions">
                  <div class="field-line">
                    <b-row>
                      <b-col><b>Total</b></b-col>
                      <b-col cols="3" class="text-right">
                        <b>{{ total | currency }}</b>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="field-line">
                    <b-row>
                      <b-col cols="5">
                        <b-checkbox v-model="dontPayNow" id="dontPayNow">Ne pas payer</b-checkbox>
                      </b-col>
                      <b-col cols="4" class="text-right">
                        <b v-if="!dontPayNow">Nombre de paiements: </b>
                      </b-col>
                      <b-col>
                        <b-form-input type="number" v-if="!dontPayNow" min="1" step="1" v-model="paymentsCount"></b-form-input>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="payment-box" v-for="payment of payments" :key="payment.id">
                    <b-row>
                      <b-col>
                        <b-form-group
                          :id="'payment-date-group' + payment.id"
                          :label-for="'payment-date' + payment.id"
                          :description="'Date de retrait - ' + (-payment.id)"
                        >
                          <b-form-input
                            type="date" :id="'payment-date' + payment.id" v-model="payment.paymentDate"
                          >
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          :id="'payment-modes-group' + payment.id"
                          :label-for="'payment-modes' + payment.id"
                          description="Mode de paiement"
                        >
                          <b-form-select
                            :id="'payment-modes' + payment.id"
                            v-model="payment.paymentMode"
                            @change="paymentModeChanged(payment)"
                            required
                          >
                            <b-form-select-option
                              :value="item"
                              v-for="item in paymentModes"
                              :key="item.id"
                            >
                              {{ item.name }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          :id="'amount-group' + payment.id"
                          :label-for="'amount' + payment.id"
                          description="Montant"
                          required
                        >
                          <decimal-input
                            :id="'amount' + payment.id"
                            v-model="payment.amount"
                          ></decimal-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-show="payment.paymentMode.bank || payment.paymentMode.isNumberRequired">
                      <b-col>
                        <b-row>
                          <b-col cols="6">
                            <b-form-group
                              :id="'bank-group' + payment.id"
                              description="Banque"
                              :label-for="'bank-name' + payment.id"
                            >
                              <vue-bootstrap-typeahead
                                :id="'bank-name' + payment.id"
                                v-model="payment.bankName"
                                :data="banks"
                                :disabled="!payment.paymentMode.bank"
                                :required="payment.paymentMode.bank"
                                :ref="'bankNameTypeAhead' + payment.id"
                                @hit="onBankSelected"
                                :minMatchingChars="2"
                                :disableFiltering="true"
                              >
                              </vue-bootstrap-typeahead>
                              <div v-if="allowMoreBanks">
                                <a href @click.prevent="showMoreBanks" class="small2">
                                  Voir plus de banques
                                </a>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group
                              :id="'bank-number-group' + payment.id"
                              :description="'Numéro' + (payment.paymentMode.isNumberRequired ? ' (obligatoire)' : '')"
                              :label-for="'bank-number' + payment.id"
                            >
                              <b-form-input
                                :id="'bank-number' + payment.id"
                                v-model="payment.bankNumber"
                                :required="payment.paymentMode.isNumberRequired"
                                :disabled="!payment.paymentMode.bank && !payment.paymentMode.isNumberRequired"
                              >
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row v-show="payment.paymentMode.showEmitter">
                      <b-col>
                        <b-form-group
                          :id="'emitter-group' + payment.id"
                          description="Émetteur (si différent de la famille)"
                          :label-for="'emitter' + payment.id"
                          :disabled="!payment.paymentMode.showEmitter"
                        >
                          <b-form-input :id="'emitter' + payment.id" v-model="payment.emitter"></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-show="showPaymentComments">
                      <b-col>
                        <b-form-group
                          :id="'comments-group' + payment.id"
                          description="Commentaires"
                          :label-for="'comments' + payment.id"
                        >
                          <b-form-input :id="'comments' + payment.id" v-model="payment.comments"></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="buttons-bar">
                    <b-row>
                      <b-col class="">
                        <a
                          class="btn btn-primary"
                          href
                          @click.prevent="confirmInscriptions()"
                          :class="isPaymentValid() ? '' : 'disabled'"
                        >
                          Inscrire
                          <span v-if="!dontPayNow">et payer</span>
                        </a>
                      </b-col>
                      <b-col>
                        <div v-if="diffPayments > 0" class="diff-text1">
                          <i class="fa fa-warning"></i> Il manque {{ diffPayments|currency}}
                        </div>
                        <div v-else-if="diffPayments < 0" class="diff-text2">
                          <i class="fa fa-warning"></i> Trop perçu de {{ -diffPayments|currency}}
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="small-text">
                        <b-form-checkbox id="showPaymentComments" v-model="showPaymentComments">
                          afficher les commentaires
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import CounterLabel from '@/components/Controls/CounterLabel'
import DecimalInput from '@/components/Controls/DecimalInput'
import LoadingGif from '@/components/Controls/LoadingGif'
import AddIndividualDiscount from '@/components/Discounts/AddIndividualDiscount'
import EntitySeancesList from '@/components/Entity/EntitySeancesList'
import InscriptionInvoicing from '@/components/Youth/InscriptionInvoicing'
import { currency } from '@/filters/texts'
import { makeInvoice, makePayment, makePaymentMode } from '@/types/payments'
import { makeSeancePrice } from '@/types/tariffs'
import { makeEntitySeance, newSeanceInscription, SeanceSynthesisElt } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { sum } from '@/utils/math'

export default {
  name: 'daily-inscriptions-and-pay-modal',
  components: {
    AddIndividualDiscount,
    CounterLabel,
    InscriptionInvoicing,
    DecimalInput,
    LoadingGif,
    EntitySeancesList,
  },
  mixins: [BackendMixin],
  props: {
    day: String,
    elements: Array,
    seanceType: Object,
    youthHome: Object,
    period: Object,
    rules: Array,
  },
  data() {
    return {
      errorText: '',
      reloadInvoicing: false,
      seances: [],
      calculatePricesLoadingName: 'daily-calculatePricesLoadingName',
      priceError: '',
      allPrices: {},
      allDiscounts: {},
      userDiscounts: {},
      payments: [makePayment({ id: -1, })],
      paymentModes: [],
      banks: [],
      allBanks: [],
      allowMoreBanks: false,
      refundCancellations: {},
      dontPayNow: true,
      reloadInvoices: false,
      paymentsCount: 1,
      showPaymentComments: false,
    }
  },
  computed: {
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    seanceDate() {
      return moment(this.day).toDate()
    },
    isToday() {
      return moment(this.day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    },
    entity() {
      if (this.elements.length) {
        return this.elements[0].entity
      }
      return null
    },
    individuals() {
      return this.elements.map(elt => elt.individual)
    },
    synthesisElt() {
      return new SeanceSynthesisElt(
        0,
        this.period,
        this.seanceType,
        this.youthHome,
        this.youthHome.schoolYear,
        0,
        0
      )
    },
    newInscriptions() {
      const newInscriptions = []
      const avoidDuplicates = new Map()
      for (const seance of this.seances) {
        if (seance.hasNewInscriptions()) {
          for (const individual of this.individuals) {
            if (seance.isIndividualInscriptionNew(individual.id)) {
              const key = '' + seance.id + ':' + individual.id
              if (!avoidDuplicates.has(key)) {
                avoidDuplicates.set(key, 1)
                newInscriptions.push(newSeanceInscription(0, seance, individual))
              }
            }
          }
        }
      }
      return newInscriptions
    },
    cancellations() {
      let cancellations = []
      for (let seance of this.seances) {
        if (seance.hasCancellations()) {
          for (let individual of this.individuals) {
            if (seance.isIndividualInscriptionCancelled(individual.id)) {
              cancellations.push(newSeanceInscription(0, seance, individual))
            }
          }
        }
      }
      return cancellations
    },
    total() {
      let total = 0
      for (const ins of this.newInscriptions) {
        total += this.getFinalPrice(ins)
      }
      // for (const ins of this.cancellations) {
      //   if (!this.isCancellationRefund(ins)) {
      //     total += this.getFinalPrice(ins)
      //   }
      // }
      return total
    },
    hasInscriptions() {
      return (this.newInscriptions.length + this.cancellations.length) > 0
    },
    diffPayments() {
      return this.total - sum(this.payments.map(elt => +elt.amount))
    },
  },
  watch: {
    youthHome: function() {},
    newInscriptions: function() {},
    cancellations: function() {},
    total: function() {
      this.splitAmount(this.total)
      this.dontPayNow = (this.total === 0)
    },
    entity: function() {
      this.payments = this.initPayment()
    },
    dontPayNow: function() {
      if (this.dontPayNow) {
        this.payments = []
        this.paymentsCount = 0
      } else {
        this.paymentsCount = 1
      }
    },
    paymentsCount: function() {
      if (this.paymentsCount === 0 || this.dontPayNow) {
        this.payments = []
      } else {
        if (this.paymentsCount > this.payments.length) {
          for (let index = this.payments.length; index < this.paymentsCount; index++) {
            const id = index + 1
            const jsonData = { id: -id, 'payment_date': moment().format('YYYY-MM-DD'), }
            this.payments.push(makePayment(jsonData))
          }
        } else if (this.paymentsCount < this.payments.length) {
          this.payments = this.payments.slice(0, this.paymentsCount)
        }
        this.splitAmount(this.total)
      }
      // this.recalculateAmount()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onClose() {
      this.$emit('close', {})
    },
    async loadBanks(paymentMode) {
      this.banks = []
      if (this.entity && paymentMode.id && paymentMode.bank) {
        try {
          let url = '/api/banks-list/' + this.entity.id + '/' + paymentMode.id + '/'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.banks = resp.data['entity_banks']
          this.allBanks = resp.data['all_banks']
          this.allowMoreBanks = this.allBanks.length > 0
          if (this.banks.length === 0) {
            this.banks = this.allBanks
            this.allowMoreBanks = false
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    initPayment() {
      this.paymentsCount = 1
      return [makePayment({ 'payment_date': moment().format('YYYY-MM-DD'), })]
    },
    isSaveActive() {
      return true // (this.individual && !this.isLoading(this.seancesCreationLoading))
    },
    onSeancesLoaded($event) {
      this.seances = $event
    },
    async loadPaymentModes() {
      if (this.paymentModes.length === 0 && this.hasPerm('payments.view_paymentmode')) {
        try {
          let url = '/api/payment-modes/'
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.paymentModes = [makePaymentMode()].concat(resp.data.map(elt => makePaymentMode(elt)))
        } catch (err) {
          this.addError(this.getErrorText(err))
        }
      }
    },
    async paymentModeChanged(payment) {
      if (!payment.paymentMode.showEmitter) {
        payment.emitter = ''
      }
      if (!payment.paymentMode.bank) {
        payment.bankName = ''
      }
      if (!payment.paymentMode.bank && !payment.paymentMode.isNumberRequired) {
        payment.bankNumber = ''
      }
      await this.loadBanks(payment.paymentMode)
      const that = this
      if (this.banks.length === 1) {
        payment.bankName = this.banks[0]
      }
      setTimeout(
        () => {
          const refName = 'bankNameTypeAhead' + payment.id
          that.$refs[refName].inputValue = payment.bankName
        }, 100
      )
    },
    onBankSelected(evt) {},
    onInscriptionChanged(data) {
      this.loadPrices()
      this.seances = [].concat(this.seances)
      this.disableIncludeCancelledActivities = true
      let seance = data.seance
      let individual = data.individual
      let inscription = data.inscription
      let key = this.getPriceKey(false, seance.id, individual.id)
      let individualId = +individual.id
      if (inscription && this.userDiscounts[individualId]) {
        this.allDiscounts[key] = { ...this.userDiscounts[individualId], }
      } else {
        this.allDiscounts[key] = null
      }
    },
    getPriceKey(cancellation, seanceId, individualId) {
      return (cancellation ? 'C-' : '') + seanceId + '/' + individualId
    },
    getPriceObj(cancellation, inscription) {
      let priceKey = this.getPriceKey(cancellation, inscription.seance.id, inscription.individual.id)
      if (priceKey in this.allPrices) {
        return this.allPrices[priceKey]
      }
      return null
    },
    getWorkshopPrice(inscription, workshopInscription) {
      let price = 0
      const workshop = inscription.seance.getWorkshop(workshopInscription.workshop)
      if (workshop && workshop.price) {
        const basePrice = workshop.price
        price = basePrice
        if (workshop.discountable) {
          for (const discount of this.getDiscount(inscription)) {
            if (discount.percentage) {
              price -= basePrice * discount.percentage / 100
            }
          }
        }
      }
      return price
    },
    getBasePrice(inscription) {
      let obj = this.getPriceObj(false, inscription)
      let price = obj ? obj.getPrice() : 0
      return price
    },
    getPriceTitle(inscription) {
      return 'Prix avant réduction: ' + currency(this.getBasePrice(inscription))
    },
    getCancelPrice(inscription) {
      // Prix d'annulation
      let obj = this.getPriceObj(true, inscription)
      let price = obj ? obj.getPrice() : 0
      return price
    },
    getPriceMessage(inscription) {
      let obj = this.getPriceObj(false, inscription)
      let message = obj ? obj.message : ''
      return message
    },
    isCancellationRefund(cancellation) {
      let key = cancellation.getKey()
      if (this.refundCancellations.hasOwnProperty(key)) {
        return this.refundCancellations[key]
      }
      return false
    },
    changeCancellationRefund(cancellation, value) {
      this.refundCancellations[cancellation.getKey()] = value
      this.refundCancellations = { ...this.refundCancellations, }
    },
    getFinalPrice(inscription) {
      let basePrice = this.getBasePrice(inscription)
      let multiplier = this.getMultiplierDelta(inscription)
      basePrice = basePrice * multiplier
      let price = basePrice
      if ((price > 0) || (inscription.seance.allowDiscountOnFreePrice)) {
        for (const discount of this.getDiscount(inscription)) {
          if (discount.amount) {
            price -= discount.amount
          }
          if (discount.percentage) {
            price -= basePrice * discount.percentage / 100
          }
        }
      }
      let workshopInscriptions = inscription.seance.getWorkshopInscriptions(inscription.individual.id)
      for (const wsIns of workshopInscriptions) {
        price += this.getWorkshopPrice(inscription, wsIns)
      }
      return price
    },
    isInvoiced(inscription) {
      let obj = this.getPriceObj(false, inscription)
      return (obj !== null) ? obj.isInvoiced() : false
    },
    getWelfare(inscription) {
      let priceKey = this.getPriceKey(false, inscription.seance.id, inscription.individual.id)
      if (priceKey in this.allPrices) {
        return this.allPrices[priceKey].welfare
      }
      return []
    },
    getDiscount(inscription) {
      let discountKey = this.getPriceKey(false, inscription.seance.id, inscription.individual.id)
      const price = this.getBasePrice(inscription)
      if (price || inscription.seance.allowDiscountOnFreePrice) {
        if (discountKey in this.allDiscounts) {
          let discount = this.allDiscounts[discountKey]
          if (discount) {
            return [discount]
          }
        }
      }
      return []
    },
    async calculateInscriptionsPrices(newInscriptions, cancellations) {
      if (this.entity && this.entity.id > 0) {
        let data = []
        for (const inscription of newInscriptions) {
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: false,
            }
          )
        }
        for (let inscription of cancellations) {
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: true,
            }
          )
        }
        let url = '/api/youth/entity-seances-prices/' + this.entity.id + '/'
        let backendApi = new BackendApi('post', url)
        let prices = {}
        try {
          let resp = await backendApi.callApi(data)
          for (let elt of resp.data) {
            let priceKey = this.getPriceKey(elt.cancelled, elt.seance, elt.individual)
            prices[priceKey] = makeSeancePrice(elt)
          }
          this.allPrices = { ...prices, }
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async loadPrices() {
      this.startLoading(this.calculatePricesLoadingName)
      await this.calculateInscriptionsPrices(this.newInscriptions, this.cancellations)
      for (let cancellation of this.cancellations) {
        let key = cancellation.getKey()
        if (!this.refundCancellations.hasOwnProperty(key)) {
          this.refundCancellations[key] = !this.isInvoiced(cancellation)
        }
      }
      this.refundCancellations = { ...this.refundCancellations, }
      this.endLoading(this.calculatePricesLoadingName)
    },
    init() {
      this.loadPaymentModes()
    },
    getPaymentData(payment) {
      return {
        amount: Math.round(payment.amount * 100) / 100,
        payment_mode: payment.paymentMode.id ? payment.paymentMode.id : null,
        emitter: payment.emitter,
        comments: this.showPaymentComments ? payment.comments : '',
        bank_name: payment.bankName,
        bank_number: payment.bankNumber,
        payment_date: '' + payment.paymentDate + 'T00:00',
      }
    },
    async onPay(invoice) {
      this.errorText = ''
      if (this.entity) {
        try {
          const url = '/api/entity/' + this.entity.id + '/create-payment/'
          const backendApi = new BackendApi('post', url)
          const payments = this.payments.map(this.getPaymentData)
          const data = {
            invoices: [invoice.id],
            payments: payments,
            credits: [], // TODO this.selectedCredits.map(elt => elt.id),
          }
          // if (this.takenCredit !== this.maxCredit) {
          //   data.taken_credit = this.takenCredit
          // }
          await backendApi.callApi(data)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async confirmInscriptions() {
      if (this.entity) {
        let data = []
        for (const inscription of this.cancellations) {
          let workshops = inscription.seance.getOriginalWorkshopInscriptions(inscription.individual.id)
          data.push(
            {
              seance: inscription.seance.id,
              individual: inscription.individual.id,
              cancelled: true,
              refund: this.isCancellationRefund(inscription),
              discounts: [],
              workshops: workshops.map(elt => { return { workshop: elt.workshop, moment: elt.moment, } }),
            }
          )
        }
        for (const inscription of this.newInscriptions) {
          let workshops = inscription.seance.getWorkshopInscriptions(inscription.individual.id)
          const discounts = this.getDiscounts(inscription).map(
            (discount) => {
              if (discount.showPercentage) {
                return {
                  amount: 0, comments: discount.comments, percentage: discount.percentage,
                }
              } else {
                return {
                  amount: discount.amount, comments: discount.comments, percentage: 0,
                }
              }
            }
          )
          const insData = {
            seance: inscription.seance.id,
            individual: inscription.individual.id,
            cancelled: false,
            workshops: workshops.map(elt => { return { workshop: elt.workshop, moment: elt.moment, } }),
            discounts: discounts,
          }
          const multiplier = this.getMultiplier(inscription)
          if (multiplier) {
            insData.multiplier = multiplier
          }
          data.push(insData)
        }
        let url = '/api/youth/entity-create-inscriptions/' + this.entity.id + '/'
        if (!this.dontPayNow) {
          url += '?create-invoice=1'
        }
        let backendApi = new BackendApi('post', url)
        try {
          let resp = await backendApi.callApi(data)
          const invoice = resp.data.invoice ? makeInvoice(resp.data.invoice) : null
          let updatedSeances = resp.data.seances.map(elt => makeEntitySeance(elt))
          let existingSeanceIds = this.seances.map(elt => elt.id)
          let createdInscriptions = []
          let cancelledSeances = []
          for (let updatedSeance of updatedSeances) {
            let seanceIndex = existingSeanceIds.indexOf(updatedSeance.id)
            if (seanceIndex >= 0) {
              let seance = this.seances[seanceIndex]
              seance.reinit(updatedSeance)
            }
          }
          if (invoice) {
            await this.onPay(invoice)
          }
          this.reloadInvoices = true
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
      return []
    },
    isPaymentValid() {
      if (this.dontPayNow) {
        return true
      } else {
        if (this.payments.length > 0) {
          return (this.payments.filter(elt => !elt.isValid()).length === 0)
        } else {
          return false
        }
      }
    },
    splitAmount(amount) {
      if (this.paymentsCount && this.payments.length) {
        let splitedAmount = amount
        const stepAmount = Math.round((splitedAmount / this.payments.length) * 100) / 100
        let amountsSum = 0
        for (let index = 0; index < this.payments.length; index++) {
          const isLast = index === (this.payments.length - 1)
          if (isLast) {
            this.payments[index].amount = amount - amountsSum
            this.payments[index].amount = Math.round(this.payments[index].amount * 100) / 100
          } else {
            this.payments[index].amount = stepAmount
            amountsSum += stepAmount
          }
        }
        this.payments = this.payments.concat([])
      }
    },
    getDiscounts(inscription) {
      const discounts = []
      const discountKey = this.getPriceKey(inscription.seance.id, inscription.individual.id)
      const price = this.getBasePrice(inscription)
      if (price || inscription.seance.allowDiscountOnFreePrice) {
        if (discountKey in this.allDiscounts) {
          let discount = this.allDiscounts[discountKey]
          if (discount) {
            discounts.push(discount)
          }
        }
        const individualId = inscription.individual.id
        if (this.userDiscounts[individualId]) {
          discounts.push(this.userDiscounts[individualId])
        }
      }
      return discounts
    },
    getMultiplier(inscription) {
      return inscription.seance.getIndividualNewMultiplier(inscription.individual.id)
    },
    getMultiplierDelta(inscription) {
      return inscription.seance.getIndividualMultiplierDelta(inscription.individual.id)
    },
    getInitialMultiplier(inscription) {
      return inscription.seance.getIndividualInitialMultiplier(inscription.individual.id)
    },
    onDiscountsChanged(discounts) {
      this.userDiscounts = discounts
    },
    showMoreBanks() {
      this.banks = this.allBanks
      this.allowMoreBanks = false
    },
  },
  created() {
    this.payments = this.initPayment()
    this.init()
  },
}
</script>
<style lang="less">
.field-line-small {
  font-size: 11px;
}
.buttons-bar a {
  display: inline-block;
  margin-right: 2px;
}
.multiplier {
  padding: 2px;
  background: #eee;
  border: solid 1px #ccc;
  font-size: 10px;
}
.diff-text1 {
  padding: 20px;
  background: #c84444;
  color: #fff;
}
.diff-text2 {
  padding: 20px;
  background: #f77438;
  color: #fff;
}
</style>
