
import { BackendApi, openDocument } from '@/utils/http'
import { SeanceInscription } from '@/types/youth'
import { slugify } from '@/utils/strings'

export async function loadLinkDocuments(dateAsStr: string, inscriptions: SeanceInscription[]): Promise<any> {
  return new Promise(
    async function(resolve, reject) {
      const docsSavedOn: any = {}
      const missingDocIds = []
      try {
        const data = {
          'elements': inscriptions.map(
            elt => { return { individual: elt.individual.id, entity: elt.entity ? elt.entity.id : 0, } }
          ),
        }
        let url = '/documents/api/all-individuals-link-document/'
        let backendApi = new BackendApi('post', url)
        let resp = await backendApi.callApi(data)
        const documents = resp.data
        const docIds = []
        for (const doc of documents) {
          const docKey = '' + doc.individual + '-' + doc.entity
          if (doc.document) {
            docIds.push(doc.document)
            docsSavedOn[docKey] = doc.date
          } else {
            missingDocIds.push(docKey)
          }
        }
        if (docIds.length) {
          const docUrl = '/documents/download-all-individuals-link-document/<key>/'
          const docSlug = dateAsStr
          const docContent = docIds.join('-')
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            reject(err)
          }
        } else {
          reject(new Error('Pas de fiches de liaison'))
        }
      } catch (err) {
        reject(err)
      }
      resolve({ docsSavedOn, missingDocIds, })
    }
  )
}

export async function loadIndividualLinkDocuments(label: string, items: any[]): Promise<any> {
  return new Promise(
    async function(resolve, reject) {
      const docsSavedOn: any = {}
      const missingDocIds = []
      try {
        const data = {
          'elements': items.map(
            item => { return { individual: item.individual.id, entity: item.entity ? item.entity.id : 0, } }
          ),
        }
        let url = '/documents/api/all-individuals-link-document/'
        let backendApi = new BackendApi('post', url)
        let resp = await backendApi.callApi(data)
        const documents = resp.data
        const docIds = []
        for (const doc of documents) {
          const docKey = '' + doc.individual + '-' + doc.entity
          if (doc.document) {
            docIds.push(doc.document)
            docsSavedOn[docKey] = doc.date
          } else {
            missingDocIds.push(docKey)
          }
        }
        if (docIds.length) {
          const docUrl = '/documents/download-all-individuals-link-document/<key>/'
          const docSlug = slugify(label)
          const docContent = docIds.join('-')
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            reject(err)
          }
        } else {
          reject(new Error('Pas de fiches de liaison'))
        }
      } catch (err) {
        reject(err)
      }
      resolve({ docsSavedOn, missingDocIds, })
    }
  )
}
