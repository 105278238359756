<template>
  <span class="seances-indicator">
    <span
      v-for="elt of elements"
      :key="elt.id"
      :title="elt.title"
      v-b-tooltip.hover
      class="seance-indicator-item"
      :class="{ caption: caption, }"
      :style="getStyle(elt)"
    >
      <span v-if="caption">
        {{ elt.name }}
      </span>
      <span v-else>
        <i class="far fa-check-square" v-if="isChecked(elt)"></i>
        <i class="far fa-square" v-else></i>
      </span>
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'SeancesIndicator',
  props: {
    seances: Array,
    inscriptions: {
      type: Array,
      defaultValue: [],
    },
    caption: {
      type: Boolean,
      defaultValue: false,
    },
    printMode: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
    }
  },
  watch: {
    seances: function() {},
    inscriptions: function() {},
    printMode: function() {},
  },
  computed: {
    elements() {
      const elements = [
        { id: -1, name: 'M', title: 'Matin', order: -10, },
        { id: -3, name: 'AM', title: 'Après-Midi', order: 10, }
      ]
      let hasPicnic = this.seances.filter(elt => elt.lunch && elt.picnic).length
      let hasLunch = this.seances.filter(elt => elt.lunch && !elt.picnic).length
      if (hasPicnic) {
        elements.push({ id: -5, name: 'PN', title: 'Pique-Nique', order: 5, })
      }
      if (this.seances.filter(elt => elt.evening).length) {
        elements.push({ id: -4, name: 'S', title: 'Soirée', order: 20, })
      }
      if (hasLunch || !hasPicnic) {
        elements.push({ id: -2, name: 'R', title: 'Repas', order: 0, })
      }
      for (const seance of this.seances) {
        if ((!seance.morning) && (!seance.lunch) && (!seance.afternoon) && (!seance.evening)) {
          elements.push(
            {
              id: seance.id,
              name: seance.getCodeOrAlias(),
              title: seance.getCodeName() + ' ' + seance.getBaseName(),
              order: seance.order,
            })
        }
      }
      return elements.sort((a, b) => compareNumbers(a.order, b.order))
    },
  },
  methods: {
    isHighlighted(elt) {
      return elt.id > 0
    },
    isChecked(elt) {
      if (elt.id === -1) {
        // matin
        return this.inscriptions.filter(item => item.seance.morning).length > 0
      }
      if (elt.id === -2) {
        // repas
        return this.inscriptions.filter(
          item => (item.seance.lunch && !item.seance.picnic)
        ).length > 0
      }
      if (elt.id === -3) {
        // après-midi
        return this.inscriptions.filter(item => item.seance.afternoon).length > 0
      }
      if (elt.id === -4) {
        // matin
        return this.inscriptions.filter(item => item.seance.evening).length > 0
      }
      if (elt.id === -5) {
        // matin
        return this.inscriptions.filter(
          item => (item.seance.lunch && item.seance.picnic)
        ).length > 0
      }
      return this.inscriptions.map(item => item.seance.id).indexOf(elt.id) >= 0
    },
    getStyle(elt) {
      const lengths = [25, elt.name.length * 6]
      const width = Math.max(...lengths)
      const style = {
        'font-size': this.caption ? '8px' : '18px',
        'display': 'inline-block',
        'margin': '0 0 0 6px',
        'color': '#222',
        'text-align': 'center',
        width: '' + width + 'px',
        'font-family': 'monospace, sans-serif',
        'border': this.caption ? 'solid 1px #888' : 'none',
      }
      if (this.isHighlighted(elt)) {
        const color = (this.printMode) ? '#ddd' : '#caa'
        style['background-color'] = color + ' !important'
      }
      return style
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
.seances-indicator > .seance-indicator-item {
}
.seances-indicator > .seance-indicator-item.caption {
}
.seances-indicator > .seance-indicator-item:last-of-type {
  margin: 0;
}

</style>
