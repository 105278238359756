<template>
  <span class="seance-inscription-moment" v-if="dayTimes">
    <moment-indicator
      :morning="dayTimes.morning"
      :lunch="dayTimes.lunch"
      :afternoon="dayTimes.afternoon"
      :evening="dayTimes.evening"
      :picnic="dayTimes.picnic"
      :show-evening="showEvening"
      :show-picnic="showPicnic"
      :show-lunch="showLunch"
      :smaller="smaller"
      :labels="labels"
      :letters="letters"
    >
    </moment-indicator>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { getInscriptionsDayTimes } from '@/types/youth'
import MomentIndicator from '@/components/Youth/MomentIndicator'

export default {
  name: 'SeanceInscriptionMomentIndicator',
  components: { MomentIndicator, },
  props: {
    inscriptions: Array,
    showEvening: {
      type: Boolean,
      default: true,
    },
    showPicnic: {
      type: Boolean,
      default: true,
    },
    showLunch: {
      type: Boolean,
      default: true,
    },
    smaller: {
      type: Boolean,
      default: false,
    },
    labels: Array,
    letters: Array,
  },
  data() {
    return {
      dayTimes: null,
    }
  },
  watch: {
    inscriptions: function() {
      this.dayTimes = getInscriptionsDayTimes(this.inscriptions)
    },
    showEvening: function() {},
    showLunch: function() {},
    showPicnic: function() {},
  },
  computed: {
  },
  methods: {
  },
  mounted() {
    this.dayTimes = getInscriptionsDayTimes(this.inscriptions)
  },
}
</script>
<style lang="less">
.seance-inscription-moment > span{
  font-size: 16px;
  margin: 0 1px;
  color: #000;
}
</style>
