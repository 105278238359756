<template>
  <span>
    <span v-for="elt of seancesCounter" :key="elt.id" :style="style">
      {{ elt.name }} : {{ elt.counter }}
    </span>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'SeanceCounters',
  mixins: [BackendMixin],
  props: {
    elements: Array,
    seances: Array,
  },
  data() {
    return {
    }
  },
  watch: {
    elements: function() {},
  },
  computed: {
    seancesCounter() {
      return this.getSeancesCounter(this.elements)
    },
    style() {
      return {
        'font-size': '10px',
        'padding': '3px',
        'border': 'solid 1px #e0e0e0',
        'margin-left': '3px',
        'display': 'inline-block',
      }
    },
  },
  methods: {
    getSeancesCounter(elements) {
      let seancesCounter = new Map()
      for (const seance of this.seances) {
        seancesCounter.set(seance.id, 0)
      }
      for (const element of elements) {
        for (const ins of element.inscriptions) {
          if (seancesCounter.has(ins.seance.id)) {
            seancesCounter.set(ins.seance.id, seancesCounter.get(ins.seance.id) + 1)
          }
        }
      }
      let results = []
      for (const seance of this.seances) {
        results.push(
          {
            id: seance.id,
            name: seance.getCodeOrAlias(),
            counter: seancesCounter.get(seance.id),
          }
        )
      }
      return results
    },
  },
}
</script>
<style lang="less">
</style>
