<template>
  <span class="absence-clocking" v-if="hasPerm('youth.view_seanceinscription')">
    <div v-if="inscriptions">
      <span
        v-for="seance in seances"
        :key="seance.id"
        :title="seance.name"
        class="absence-grid-col"
      >
        <span v-if="getInscription(seance)">
          <absence-indicator
            :inscription="getInscription(seance)"
            :individual="individual"
            @updated="onRefreshInscription(element, $event)"
          ></absence-indicator>
        </span>
        <span v-else>
        </span>
      </span>
    </div>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import AbsenceIndicator from '@/components/Youth/AbsenceIndicator'

export default {
  name: 'AbsenceClocking',
  components: {
    AbsenceIndicator,
  },
  mixins: [BackendMixin],
  props: {
    element: Object,
    inscriptions: Array,
    seances: Array,
  },
  data() {
    return {
    }
  },
  computed: {
    individual() {
      if (this.element) {
        return this.element.individual
      }
      return null
    },
    entity() {
      if (this.element) {
        return this.element.entity
      }
      return null
    },
    entitySeances() {
      if (this.element) {
        return this.element.entitySeances
      }
      return []
    },
    hasChanged() {
      if (this.element) {
        return this.element.entitySeances.filter(
          seance => seance.hasCancellations() || seance.hasNewInscriptions()
        ).length > 0
      }
      return false
    },
  },
  watch: {
    element: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getInscription(seance) {
      for (const ins of this.inscriptions) {
        if (!ins.cancelled && ins.seance.id === seance.id) {
          return ins
        }
      }
      return null
    },
    onRefreshInscription(element, inscription) {
      this.$emit('refreshed', inscription)
    },
  },
  mounted() {
  },
}
</script>
<style lang="less" scoped>
  .absence-grid-col {
    display: inline-block;
    height: 24px;
    width: 36px;
    text-align: center;
  }
</style>
