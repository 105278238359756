<template>
  <div class="children-list">
    <table class="table-striped table table-full">
      <tr>
        <th>Nom</th>
        <th>Prénom</th>
        <th>Nom Parent1</th>
        <th>Prénom Parent1</th>
        <th>Téléphone Parent1</th>
        <th>Email Parent1</th>
        <th>Nom Parent2</th>
        <th>Prénom Parent2</th>
        <th>Téléphone Parent2</th>
        <th>Email Parent2</th>
        <th>Téléphone</th>
        <th>Email</th>
      </tr>
      <tr v-for="child of children" :key="child.id">
        <td>{{ child.individual.lastName }}</td>
        <td>{{ child.individual.firstName }}</td>
        <td>
          <span v-if="child.parent1">{{ child.parent1.lastName }}</span>
        </td>
        <td>
          <span v-if="child.parent1">{{ child.parent1.firstName }}</span>
        </td>
        <td>
          <span v-if="child.parent1">{{ child.parent1.cellPhone }}</span>
        </td>
        <td>
          <span v-if="child.parent1">{{ child.parent1.email }}</span>
        </td>
        <td>
          <span v-if="child.parent2">{{ child.parent2.lastName }}</span>
        </td>
        <td>
          <span v-if="child.parent2">{{ child.parent2.firstName }}</span>
        </td>
        <td>
          <span v-if="child.parent2">{{ child.parent2.cellPhone }}</span>
        </td>
        <td>
          <span v-if="child.parent2">{{ child.parent2.email }}</span>
        </td>
        <td>
          {{ firstCellPhone(child) }}
        </td>
        <td>
          {{ firstEmail(child) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ChildrenTable',
  components: {
  },
  props: {
    children: Array,
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  watch: {
    children: function() {},
  },
  computed: {
  },
  methods: {
    firstEmail(child) {
      if (child.parent1 && child.parent1.email) {
        return child.parent1.email
      }
      if (child.parent2 && child.parent2.email) {
        return child.parent2.email
      }
      return ''
    },
    firstCellPhone(child) {
      if (child.parent1 && child.parent1.cellPhone) {
        return child.parent1.cellPhone
      }
      if (child.parent2 && child.parent2.cellPhone) {
        return child.parent2.cellPhone
      }
      return ''
    },

  },
}
</script>

<style lang="less">
</style>
