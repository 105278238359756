<template>
  <div>
    <span
      v-for="elt of dayTimesCounter"
      :key="elt.daytime.id"
      :style="style"
      class="no-wrap"
      @click.prevent="onClick(elt)"
      :class="{ 'day-time-selector' : selector, selected: isSelected(elt), mode: selectionMode, }"
    >
      {{ elt.daytime.name }} : {{ elt.counter }}
    </span>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { BackendMixin } from '@/mixins/backend'
import { DayTimeValues, getDayTimes, getInscriptionsDayTimes } from '@/types/youth'
import { compareNumbers } from '@/utils/sorting'

export default {
  name: 'DayTimeCounters',
  mixins: [BackendMixin],
  props: {
    elements: Array,
    selector: {
      type: Boolean,
      default: false,
    },
    baseElements: Array,
    labels: Array,
  },
  data() {
    return {
      selected: DayTimeValues.None,
      selectionMode: false,
    }
  },
  watch: {
    elements: function() {},
    baseElements: function() {},
  },
  computed: {
    dayTimesCounter() {
      return this.getTimesCounter(this.elements, this.baseElements || [])
    },
    style() {
      return {
        'font-size': '10px',
        'padding': '3px',
        'border': 'solid 1px #e0e0e0',
        'margin-left': '3px',
        'display': 'inline-block',
        'vertical-align': 'top',
      }
    },
  },
  methods: {
    getTimesCounter(elements, baseElements) {
      let dayTimesCounter = {}
      dayTimesCounter[DayTimeValues.Morning] = 0
      dayTimesCounter[DayTimeValues.Lunch] = 0
      dayTimesCounter[DayTimeValues.Afternoon] = 0
      dayTimesCounter[DayTimeValues.Evening] = 0
      dayTimesCounter[DayTimeValues.Picnic] = 0

      let baseCounter = {}
      baseCounter[DayTimeValues.Morning] = 0
      baseCounter[DayTimeValues.Lunch] = 0
      baseCounter[DayTimeValues.Afternoon] = 0
      baseCounter[DayTimeValues.Evening] = 0
      baseCounter[DayTimeValues.Picnic] = 0

      for (const element of elements) {
        const dayTimes = getInscriptionsDayTimes(element.inscriptions)
        if (dayTimes.morning) {
          dayTimesCounter[DayTimeValues.Morning] += dayTimes.morning
        }
        if (dayTimes.lunch) {
          dayTimesCounter[DayTimeValues.Lunch] += dayTimes.lunch
        }
        if (dayTimes.afternoon) {
          dayTimesCounter[DayTimeValues.Afternoon] += dayTimes.afternoon
        }
        if (dayTimes.evening) {
          dayTimesCounter[DayTimeValues.Evening] += dayTimes.evening
        }
        if (dayTimes.picnic) {
          dayTimesCounter[DayTimeValues.Picnic] += dayTimes.picnic
        }
      }
      for (const element of baseElements) {
        const dayTimes = getInscriptionsDayTimes(element.inscriptions)
        if (dayTimes.morning) {
          baseCounter[DayTimeValues.Morning] += dayTimes.morning
        }
        if (dayTimes.lunch) {
          baseCounter[DayTimeValues.Lunch] += dayTimes.lunch
        }
        if (dayTimes.afternoon) {
          baseCounter[DayTimeValues.Afternoon] += dayTimes.afternoon
        }
        if (dayTimes.evening) {
          baseCounter[DayTimeValues.Evening] += dayTimes.evening
        }
        if (dayTimes.picnic) {
          baseCounter[DayTimeValues.Picnic] += dayTimes.picnic
        }
      }
      const ordering = [
        DayTimeValues.None, DayTimeValues.Morning, DayTimeValues.Lunch, DayTimeValues.Picnic,
        DayTimeValues.Afternoon, DayTimeValues.Evening
      ]
      return getDayTimes(this.labels || []).map(daytime => {
        return {
          daytime: daytime,
          counter: dayTimesCounter[daytime.id],
          base: baseCounter[daytime.id],
        }
      }).filter(daytimeCounter => daytimeCounter.counter > 0 || daytimeCounter.base > 0).sort(
        (val1, val2) => {
          return compareNumbers(ordering.indexOf(val1.daytime.id), ordering.indexOf(val2.daytime.id))
        }
      )
    },
    isSelected(elt) {
      return (this.selector && this.selected === elt.daytime.id)
    },
    onClick(elt) {
      if (this.selector) {
        if (this.selected === elt.daytime.id) {
          if (this.selectionMode) {
            this.selected = DayTimeValues.None
            this.selectionMode = false
          } else {
            this.selectionMode = true
          }
        } else {
          this.selected = elt.daytime.id
          this.selectionMode = false
        }
        this.$emit('click', { daytime: this.selected, mode: this.selectionMode, })
      }
    },
  },
}
</script>
<style lang="less">
.day-time-selector {
  cursor: pointer;
}
.day-time-selector.selected {
  color: #fff;
  background: #de2323;
}
.day-time-selector.selected.mode {
  color: #fff;
  background: #068a21;
}
</style>
