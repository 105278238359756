<template>
  <span v-if="hasPerm('youth.change_seanceinscription')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave()"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Modifier l'inscription"
      :ok-disabled="formInvalid"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-clock-o"></i> Modifier une inscription
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="inscription" class="ins-box">
        Modifier l'inscription de "{{ individual.firstAndLastName() }}"
        à la séance "{{ inscription.seance.getShortName() }}"
      </div>
      <b-row v-if="allowedSeances.length === 0">
        <b-col>
          Aucune séance de remplacement
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <b-form-group
            label="Sélectionnez la séance de remplacement"
            label-for="seance"
            description="L'inscription sera annulée et remplacée par une inscription à la séance sélectionnée"
          >
            <radio-select
              :choices="allowedSeances"
              id="seance"
              v-model="selectedSeance"
              :name-callback="nameCallback"
              inline
            ></radio-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeSeanceInscription } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import RadioSelect from '@/components/Controls/RadioSelect'

export default {
  name: 'SwitchSeanceInscriptionModal',
  components: { RadioSelect, },
  mixins: [BackendMixin],
  data() {
    return {
      selectedSeance: null,
      errorText: '',
    }
  },
  props: {
    individual: Object,
    inscription: Object,
    seances: Array,
    modalId: {
      type: String,
      default: 'switch-seance-inscription-modal',
    },
  },
  watch: {
    inscription: function() {},
    seances: function() {
      this.selectedSeance = null
    },
  },
  computed: {
    allowedSeances() {
      if (this.inscription) {
        return this.seances.filter(
          seance => {
            return (
              (seance.id !== this.inscription.seance.id) &&
              (seance.morning === this.inscription.seance.morning) &&
              (seance.lunch === this.inscription.seance.lunch) &&
              (seance.afternoon === this.inscription.seance.afternoon) &&
              (seance.evening === this.inscription.seance.evening)
            )
          }
        )
      }
      return []
    },
    formInvalid() {
      return this.selectedSeance === null && this.inscription !== null
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    nameCallback(elt) {
      return elt.getShortName()
    },
    async onSave() {
      if (!this.formInvalid) {
        let url = '/api/youth/entity-switch-inscription/' + this.inscription.entity.id + '/'
        const backendApi = new BackendApi('post', url)
        const data = {
          inscription: this.inscription.id,
          individual: this.individual.id,
          seance: this.selectedSeance.id,
        }
        try {
          const resp = await backendApi.callApi(data)
          await this.addSuccess('L\'inscription a été mise à jour')
          this.$bvModal.hide(this.modalId)
          this.$emit(
            'done',
            {
              replaced: this.inscription,
              inscription: makeSeanceInscription(resp.data),
              individual: this.individual,
            }
          )
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
}
</script>

<style scoped>
.error {
  background: #eee;
  color: #cc0000;
  padding: 4px 10px;
}
.ins-box {
  padding: 10px;
  background: #eee;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
